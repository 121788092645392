import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { AppConfig } from '../app.config';
import { SharedConfig, FieldMap } from '../shared/shared.config';
import { WorkflowService } from '../../framework/workflow.service';
import { LocalStorageService } from '../../framework/localstorage.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { TranslateService } from '../../framework/i18n';

// import 'rxjs/add/operator/catch';
import { Subject, throwError } from 'rxjs';

@Injectable()
export class InternationalService {
  headerOptions: any;
  intlConfig: Object;
  nextURL: string;
  action: string;
  nextDisplayName: string;

  fieldMap: FieldMap = SharedConfig.fieldMap;
  acicSubHeaderData = new Subject<any>();
  acicSubHeaderData$ = this.acicSubHeaderData.asObservable();
  asicIDHeaderData = new Subject<any>();
  asicIDHeaderData$ = this.asicIDHeaderData.asObservable();

  featureInputCollectionPropMap: Object = {
    'Custom Form': 'candidate_form_fields_list',
    'DBS': 'ListOfinputs'
  };

  constructor(private _http: HttpClient,
    private _workflow: WorkflowService,
    private localStorage: LocalStorageService,
    public _ts: TranslateService,
    private _authHttp: HttpClient
  ) {

  }

  inIt() {
    this.headerOptions = this._workflow.getHeaderOptions();
    this.intlConfig = this._workflow.getStepConfig('international-data-v1', 0);
    this.action = this.intlConfig['action'];
    this.nextURL = this._workflow.getNextURL('international-data-v1');
    this.nextDisplayName = this._workflow.getNextMenuDisplayName('international-data-v1');
    this.headerOptions = this._workflow.getHeaderOptions();
  }

  getIntlData(): Observable<any> {
    this.inIt();
    return this._http.get(AppConfig.API_ENDPOINT() + this.action + '/?t=' + new Date(), this.headerOptions)
    .pipe(
      map(res => this._extractData(res)),
      catchError(err => this._handleError(err, 'international_get_failed'))
    );
  }

  getCustomFormData() {
    let formFieldList = [];
    /*
     * there is a chance that 'forms' key can have 'candidate_form_fields_list' key & in that case
     * value for 'candidate_form_fields_list' key will be returned
    */

    if (this.intlConfig['forms'].length > 0) {
      for (let i in this.intlConfig['forms']) {
        if (this.intlConfig['forms'][i]['candidate_form_fields_list'] &&
          this.intlConfig['forms'][i]['candidate_form_fields_list'].length > 0) {
          formFieldList.push(this.intlConfig['forms'][i]);
        }
      }
    }

    return (formFieldList === undefined) ? this.intlConfig['forms'] : formFieldList; // this.getDummyCustomForms(); //
  }

  getFeatureInputCollectionPropName(feature) {
    return this.featureInputCollectionPropMap[feature];
  }

  getNextURL(): string {
    return this.nextURL;
  }

  getNextDisplayName() {
    return this.nextDisplayName;
  }

  fieldMapper(fieldList, searchKey) {
    for (let i = 0; i < fieldList[searchKey].length; i++) {
      if (this.fieldMap[fieldList[searchKey][i]['type']] !== undefined) {
        fieldList[searchKey][i]['type'] = this.fieldMap[fieldList[searchKey][i]['type']];
        if(fieldList[searchKey][i] !== undefined && fieldList[searchKey][i]['label'] !== undefined)
          fieldList[searchKey][i]['label'] = this._ts.instant(fieldList[searchKey][i]['label']);
      }
    }

    return fieldList;
  }

  getDummyCustomForms() {
    let d, k;
    let j;

    k = [
      {
        id: "5e36d1cc-e122-4374-ad33-5924636b1122",
        name: "Westpac Self-Declaration",
        is_customform: true,
        is_customconsent: false,
        is_customdisclosure: true,
        form_code: "CASE",
        candidate_form_fields_list: [
          {
            id: "c153303f-3817-4c1a-8044-d50a569260bb",
            label: "Have you ever, in Australia or elsewhere, been subject to any of the following forms of investigation or regulatory action:",
            is_hidden: false,
            type: "HTMLLabel",
            sort_order: 1,
            options: [],
            attribute_id: 2162,
            text: "<html><body>Have you ever, in Australia or elsewhere, been subject to any of the following forms of investigation or regulatory action:<br><br></body></html>"
          },
          {
            id: "4671a0d8-8474-401b-90bd-bce07ca8da04",
            label: "(a) investigation, disqualification, banning or any other regulatory action by the Australian Securities and Investments Commission (ASIC), the Australian Prudential Regulation Authority (APRA) or any other regulator? or",
            is_hidden: false,
            type: "YesNoQuestionWithExplanation",
            sort_order: 2,
            options: [],
            validation: {
              is_required: true,
              max_length: "2000"
            },
            attribute_id: 2163
          },
          {
            id: "2f130b41-bd0c-4eff-a348-50382a54d65e",
            label: "(b) investigation by any financial services institution for fraud, bribery, corruption, theft, dishonesty, misconduct or malpractice?",
            is_hidden: false,
            type: "YesNoQuestionWithExplanation",
            sort_order: 3,
            options: [],
            validation: {
              is_required: true,
              max_length: "2000"
            },
            attribute_id: 2164
          }
        ]
      }
    ];

    d = [
      {
        'id': '0970d2a7-77b3-47a3-afad-079d08d12f4f',
        'name': 'QC-A Personal Custom Form',
        'consent-name': 'consent-1.pdf',
        'is_customform': true,
        'is_customconsent': false,
        'is_customdisclosure': false,
        'form_code': 'CASE',
        'candidate_form_fields_list': [
          {
            'id': 'b25384db-fec2-406f-8df8-56863726cfa2',
            'label': 'CANDIDATE FAX',
            'validation': {
              'maximum_size': 1000,
              'is_required': true,
              'is_hidden': false,
            },
            'type': 'TextBox'
          }
        ]
      },
      {
        'id': '738d24cf-37fe-44ed-b18d-fb66d352a864',
        'name': 'COV ESigned Date',
        'consent-name': 'consent-2.pdf',
        'is_customform': true,
        'is_customconsent': false,
        'is_customdisclosure': false,
        'form_code': 'CASE',
        'candidate_form_fields_list': [
          {
            'id': '497c1799-38ce-4a7c-a53c-521234f1ac90',
            'label': 'First Name',
            'validation': {
              'maximum_size': 1000,
              'is_required': true,
              'is_hidden': false,
            },
            'type': 'TextBox'
          },
          {
            'id': '182ec7fa-2df3-4a6f-a291-5f0aba99b362',
            'label': 'Last Name',
            'validation': {
              'maximum_size': 1000,
              'is_required': true,
              'is_hidden': false,
            },
            'type': 'TextBox'
          },
          {
            'id': 'b74139aa-bd27-41a0-8771-d707f88ec27c',
            'label': 'Social Security Number',
            'validation': {
              'maximum_size': 1000,
              'is_required': true,
              'is_hidden': false,
            },
            'type': 'NumericTextBox'
          }
        ]
      },
      {
        'id': 'd704c0fa-3e6e-44e0-88a7-4289fdcaac0c',
        'name': 'COV',
        'consent-name': 'consent-3.pdf',
        'is_customform': true,
        'is_customconsent': false,
        'is_customdisclosure': false,
        'form_code': 'CASE',
        'candidate_form_fields_list': [
          {
            'id': '4bdf2e6d-e4c3-45aa-a07c-bfa77cf80aa6',
            'label': 'Date of Conviction',
            'validation': {
              'maximum_size': 1000,
              'is_required': true,
              'is_hidden': false,
            },
            'type': 'DateTextBox'
          },
          {
            'id': 'c99bbf4e-b685-4d2a-8ba3-36ddb919d181',
            'label': 'Offense',
            'validation': {
              'maximum_size': 1000,
              'is_required': true,
              'is_hidden': false,
            },
            'type': 'TextBox'
          },
          {
            'id': 'a114f409-eda0-4b07-a471-65b77c1e444c',
            'label': 'Location',
            'validation': {
              'maximum_size': 1000,
              'is_required': true,
              'is_hidden': false,
            },
            'type': 'TextBox'
          },
          {
            'id': '49292ab5-837b-4c2b-9437-e4f61f2161b4',
            'label': 'Type of Vehicle Operated (Make/Model)',
            'validation': {
              'maximum_size': 1000,
              'is_required': true,
              'is_hidden': false,
            },
            'type': 'TextBox'
          }
        ]
      },
      {
        'id': 'b5827ef6-bc37-4bb4-ab8a-06caea0ab65c',
        'name': 'JPMC Disclosure Questions',
        'is_customform': true,
        'is_customconsent': false,
        'is_customdisclosure': false,
        'form_code': 'CASE',
        'candidate_form_fields_list': [
          {
            'id': '41fdacac-9ca0-4b4c-a3d4-e84c65f4d2fd',
            'label': 'Are you the subject of any current criminal proceedings?',
            'validation': {
              'maximum_size': 1000,
              'is_required': true,
              'is_hidden': false,
            },
            'maximum_size': 1000,
            'is_required': true,
            'is_hidden': false,
            'type': 'RadioButton',
            'options': [
              {
                'id': '41fdacac-9ca0-4b4c-a3d4-e84c65f4d2fc',
                'label': 'YES',
                'position': '1',
                'isDefault': 'false',
                'value': 'TRUE'
              },
              {
                'id': '41fdacac-9ca0-4b4c-a3d4-e84c65f4d2fd',
                'label': 'NO',
                'position': '2',
                'isDefault': 'false',
                'value': 'FALSE'
              }
            ]
          },
          {
            'id': '18bb5a16-67df-4cb7-beae-b9e922de999a',
            'label': 'If you answered Yes above, please provide details; if you answered No, please put N/A in box:',
            'validation': {
              'maximum_size': 1000,
              'is_required': true,
              'is_hidden': false,
            },
            'type': 'TextArea'
          },
          {
            'id': '9df3266f-4136-4914-916f-af469c4a0af7',
            'label': 'Have you ever received or do you currently have any Financial Judgments / Individual Voluntary Arrangements / Bankruptcy Orders / Collection Accounts (in the USA or equivalent in country of residence) registered against you, settled or unsettled?',
            'validation': {
              'maximum_size': 1000,
              'is_required': true,
              'is_hidden': false,
            },
            'type': 'RadioButton',
            'options': [
              {
                'id': '41fdacac-9ca0-4b4c-a3d4-e84c65f4d2fc',
                'label': 'YES',
                'position': '1',
                'isDefault': 'false',
                'value': 'TRUE'
              },
              {
                'id': '41fdacac-9ca0-4b4c-a3d4-e84c65f4d2fd',
                'label': 'NO',
                'position': '2',
                'isDefault': 'false',
                'value': 'FALSE'
              }
            ]
          },
          {
            'id': '16bf3b5e-130b-4f7f-a9a9-0ee4baa6eb76',
            'label': 'If you answered Yes above, please provide details; if you answered No, please put N/A in box:',
            'validation': {
              'maximum_size': 1000,
              'is_required': true,
              'is_hidden': false,
            },
            'type': 'TextArea'
          },
          {
            'id': '60af6569-14b3-4fe7-9358-7f2704f6f35c',
            'label': 'Have you ever been found in breach of any rules or regulations, or are you currently being investigated or undergoing any disciplinary procedure, by any regulatory body or awarding/professional body, or previous place of employment?',
            'validation': {
              'maximum_size': 1000,
              'is_required': true,
              'is_hidden': false,
            },
            'type': 'RadioButton',
            'options': [
              {
                'id': '41fdacac-9ca0-4b4c-a3d4-e84c65f4d2fc',
                'label': 'YES',
                'position': '1',
                'isDefault': 'false',
                'value': 'TRUE'
              },
              {
                'id': '41fdacac-9ca0-4b4c-a3d4-e84c65f4d2fd',
                'label': 'NO',
                'position': '2',
                'isDefault': 'false',
                'value': 'FALSE'
              }
            ]
          },
          {
            'id': '6b8537ce-5745-473e-a13c-8b9f412bddc5',
            'label': 'If you answered Yes above, please provide details; if you answered No, please put N/A in box:',
            'validation': {
              'maximum_size': 1000,
              'is_required': true,
              'is_hidden': false,
            },
            'type': 'TextArea'
          },
          {
            'id': '1fdf9414-01b6-4c71-85a9-a3b946fa5f69',
            'label': 'Do you have any convictions for any offences which are not spent (excluding traffic offenses unless these resulted in a ban from driving or involved driving without insurance)?',
            'validation': {
              'maximum_size': 1000,
              'is_required': true,
              'is_hidden': false,
            },
            'type': 'RadioButton',
            'options': [
              {
                'id': '41fdacac-9ca0-4b4c-a3d4-e84c65f4d2fc',
                'label': 'YES',
                'position': '1',
                'isDefault': 'false',
                'value': 'TRUE'
              },
              {
                'id': '41fdacac-9ca0-4b4c-a3d4-e84c65f4d2fd',
                'label': 'NO',
                'position': '2',
                'isDefault': 'false',
                'value': 'FALSE'
              }
            ]
          },
          {
            'id': 'd82b3067-4467-4d28-bfbe-f3cc70c951cb',
            'label': 'If you answered Yes above, please provide details; if you answered No, please put N/A in box:',
            'validation': {
              'maximum_size': 1000,
              'is_required': true,
              'is_hidden': false,
            },
            'type': 'TextArea'
          },
          {
            'id': 'a1745dfa-77f1-4279-9cf1-5f71c210fd2d',
            'label': 'Have you ever been convicted of any offence involving fraud, theft, false accounting, offences against the administration of public justice (such as perjury, perverting the course of justice and intimidation of witnesses or jurors), serious tax offences or other dishonesty or any offence relating to companies, building societies, industrial and provident societies, credit unions, friendly societies, insurance, banking or other financial services, insolvency, consumer credit or consumer protection, money laundering, market manipulations or insider dealing?',
            'validation': {
              'maximum_size': 1000,
              'is_required': true,
              'is_hidden': false,
            },
            'type': 'RadioButton',
            'options': [
              {
                'id': '41fdacac-9ca0-4b4c-a3d4-e84c65f4d2fc',
                'label': 'YES',
                'position': '1',
                'isDefault': 'false',
                'value': 'TRUE'
              },
              {
                'id': '41fdacac-9ca0-4b4c-a3d4-e84c65f4d2fd',
                'label': 'NO',
                'position': '2',
                'isDefault': 'false',
                'value': 'FALSE'
              }
            ]
          },
          {
            'id': '99a0bc93-0e8a-40ec-998b-ffdc01414d0a',
            'label': 'If you answered Yes above, please provide details; if you answered No, please put N/A in box:',
            'validation': {
              'maximum_size': 1000,
              'is_required': true,
              'is_hidden': false,
            },
            'type': 'TextArea'
          },
          {
            'id': '99a0bc93-0e8a-40ec-998b-ffdc01414d0a',
            'label': 'I acknowledge that all information provided is true and correct to the best of my knowledge. I understand that any falsification or material omission on this form or any employment related documents will be grounds for disqualification of my eligibility for employment/assignment with JPMorgan Chase.',
            'type': 'HTMLLABEL'
          }
        ]
      }
    ];

    j = [
      {
        'id': '0970d2a7-77b3-47a3-afad-079d08d12f4f',
        'name': 'QC-A Personal Custom Form',
        'consent-name': 'consent-1.pdf',
        'is_customform': true,
        'is_customconsent': false,
        'is_customdisclosure': false,
        'form_code': 'CASE',
        'candidate_form_fields_list': [
          {
            'id': 'b25384db-fec2-406f-8df8-56863726cfa2',
            'label': 'CANDIDATE FAX',
            'maximum_size': 2000,
            'is_required': true,
            'is_hidden': false,
            'type': 'TextBox'
          }
        ]
      },
      {
        'id': '738d24cf-37fe-44ed-b18d-fb66d352a864',
        'name': 'COV ESigned Date',
        'consent-name': 'consent-2.pdf',
        'is_customform': true,
        'is_customconsent': false,
        'is_customdisclosure': false,
        'form_code': 'CASE',
        'candidate_form_fields_list': [
          {
            'id': '497c1799-38ce-4a7c-a53c-521234f1ac90',
            'label': 'First Name',
            'maximum_size': 30,
            'is_required': true,
            'is_hidden': false,
            'type': 'TextBox'
          },
          {
            'id': '182ec7fa-2df3-4a6f-a291-5f0aba99b362',
            'label': 'Last Name',
            'maximum_size': 30,
            'is_required': true,
            'is_hidden': false,
            'type': 'TextBox'
          },
          {
            'id': 'b74139aa-bd27-41a0-8771-d707f88ec27c',
            'label': 'Social Security Number',
            'maximum_size': 9,
            'is_required': true,
            'is_hidden': false,
            'type': 'NumericTextBox'
          },
          {
            'id': 'cc4220f9-260a-403f-9d6b-6f58ce31f908',
            'label': 'Date of Birth',
            'maximum_size': 2000,
            'is_required': true,
            'is_hidden': false,
            'type': 'DateTextBox'
          },
          {
            'id': '677a1d34-22d1-421d-a4a4-c3ebf1986b7a',
            'label': 'Driver\'s License #',
            'maximum_size': 40,
            'is_required': true,
            'is_hidden': false,
            'type': 'TextBox'
          },
          {
            'id': '5685d53f-5c98-47d6-b92b-e0f7c1736908',
            'label': 'State of Issuance',
            'maximum_size': 2000,
            'is_required': true,
            'is_hidden': false,
            'type': 'StateListBox'
          },
          {
            'id': '2dc74964-421b-4bb0-aa68-9d2b9957bf3e',
            'maximum_size': 150,
            'is_required': false,
            'is_hidden': false,
            'type': 'HTMLLabel'
          },
          {
            'id': '29f19c7e-5546-407e-b8a8-0c67b3ed6f54',
            'label': 'I Certify',
            'maximum_size': 2000,
            'is_required': true,
            'is_hidden': false,
            'type': 'CheckBox'
          }
        ]
      },
      {
        'id': 'd704c0fa-3e6e-44e0-88a7-4289fdcaac0c',
        'name': 'COV',
        'consent-name': 'consent-3.pdf',
        'is_customform': true,
        'is_customconsent': false,
        'is_customdisclosure': false,
        'form_code': 'CASE',
        'candidate_form_fields_list': [
          {
            'id': '4bdf2e6d-e4c3-45aa-a07c-bfa77cf80aa6',
            'label': 'Date of Conviction',
            'maximum_size': 2000,
            'is_required': true,
            'is_hidden': false,
            'type': 'DateTextBox'
          },
          {
            'id': 'c99bbf4e-b685-4d2a-8ba3-36ddb919d181',
            'label': 'Offense',
            'maximum_size': 50,
            'is_required': true,
            'is_hidden': false,
            'type': 'TextBox'
          },
          {
            'id': 'a114f409-eda0-4b07-a471-65b77c1e444c',
            'label': 'Location',
            'maximum_size': 50,
            'is_required': true,
            'is_hidden': false,
            'type': 'TextBox'
          },
          {
            'id': '49292ab5-837b-4c2b-9437-e4f61f2161b4',
            'label': 'Type of Vehicle Operated (Make/Model)',
            'maximum_size': 50,
            'is_required': true,
            'is_hidden': false,
            'type': 'TextBox'
          }
        ]
      }
    ];

    return k;
  }

  getDummyData() {
    let d;

    d = {
      'candidate_form_fields_list': [

      ],
      'data_error_list': [],
      'state': {
        'stage_status': 'PENDING',
        'current_stage': 'international-data',
        'current_step': 'international-step-v1',
        'current_step_index': '3',
        'current_status': 'COMPLETED'
      }
    }
      ;

    return d;
  }

  save(data): Observable<any> {
    this.inIt();
    /*         let len = data.length;

            let body = {
              'id': data['id'],
              'name': data['name'],
              'header': data['header'],
              'swipe_left': false,
              'swipe_right': false,
              'inputs': [
                 {
                'id': data['inputs'][len - 1]['id'],
                'name': data['name'],
                'type': 'HIDDEN',
                'value': data['value'],
                'list': [],
                'validation': {}
              }
             ] ,
              'state': {
                      'current_stage': 'wotc-data-v1',
                      'current_step': 'wotc-step-v1',
                      'current_step_index': 0,
                      'current_sub_step': 'state',
                      'current_sub_step_index': 1,
                      'current_iteration': 0,
                      'current_status': WotcService.stateStatus,
                      'stage_status': 'PENDING'
              }
            } ; */
    let workflowid = this.localStorage.getItem('workflow_instance_id');
    let action = '/api/v1/workflow/' + workflowid + '/international-step';
    let body = data;

    //Add EDUCATION COMPLETED Header for AppD trace
    if(body['state'] !== undefined
      && body['state']['current_stage'] === 'international-data-v1' && body['state']['stage_status'] === 'COMPLETED'){
      let traces = {};
      traces['action'] = 'AdditionalDataCompleted';
      this.headerOptions = this._workflow.getHeaderOptionsWithTraces(traces);
    }

    return this._http.put(AppConfig.API_ENDPOINT() + action, body, this.headerOptions)
    .pipe(
      map(res => this._extractData(res)),
      map(res => this._doAction(res)),
      catchError(err => this._handleError(err, 'international_put_failed'))
    );
  }

  updateState(data): Observable<any> {
    this.headerOptions = this._workflow.getHeaderOptions();
    let workflowid = this.localStorage.getItem('workflow_instance_id');
    let action = '/api/v1/workflow/' + workflowid + '/update-state/international-data';
    let body = data;

    if(body !== undefined
      && body['current_stage'] === 'international-data-v1' && body['stage_status'] === 'COMPLETED'){
      let traces = {};
      traces['action'] = 'AdditionalDataCompleted';
      this.headerOptions = this._workflow.getHeaderOptionsWithTraces(traces);
    }

     return this._http.put(AppConfig.API_ENDPOINT() + action, body, this.headerOptions)
    .pipe(
      map(res => this._extractData(res)),
      map(res => this._doAction(res)),
      catchError(err => this._handleError(err, 'update_state_put_failed'))
    );
  }

  performDBSIdentityCheck(): Observable<any> {
    this.inIt();
    let workflowid = this.localStorage.getItem('workflow_instance_id');
    let action = '/api/v1/workflow/' + workflowid + '/dbs-identity-check';
    return this._http.get(AppConfig.API_ENDPOINT() + action + '/?t=' + new Date(), this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        catchError(err => this._handleError(err, 'international_get_failed'))
      );
  }


  _extractData(res: any) {
    return res;
  }

  _doAction(response: any) {
    return response;
  }

  _handleError(error: any, eventName) {
    let _body = error || {};
    let errorMessage: string;

    console['server'](eventName, error);

    switch (error.status) {
      case 400:
      case 401:
        errorMessage = _body['error']['error'] || 'API Call Failed';
        break;
      case 404:
        break;
      case 409:
        errorMessage = "409_ERROR"; 
        break;        
      default:
        break;
    }
    return throwError(errorMessage);
  }

  setNewAcicSubHeader(subHearder) {
    this.acicSubHeaderData.next(subHearder);
  }

  setINTLREGHeader(subHearder) {
    this.asicIDHeaderData.next(subHearder);
  }

     // Method to donwload file
     downloadFile(id, locationCode): Observable<Blob> {
      let workflowid = this.localStorage.getItem('workflow_instance_id');
      let action = '/api/v1/workflow/' + workflowid + '/download/';
  
      return this._authHttp.get(AppConfig.API_ENDPOINT() + action + id + '/?doclocation=' + locationCode, {
          headers: new HttpHeaders({
              'Authorization': 'Bearer ' + this.localStorage.getItem('access_token')
          }), responseType: 'blob'
      }).pipe(
          map(res => this.documentDownload(res)),
          catchError(err => this._handleError(err, 'download_file_failed'))
      )
  }
  
  documentDownload(response: any) {
    let res = response;
  
    return res;
  }

  deepCopy(target: any): any {
    if (target === null) {
      return target;
    }
    if (target instanceof Date) {
      return new Date(target.getTime());
    }
    if (target instanceof Array) {
      const cp = [];
      target.forEach((v) => { cp.push(v); });
      return cp.map((n: any) => this.deepCopy(n));
    }
    if (typeof target === 'object' && target !== null && Object.keys(target).length > 0) {
      // const cp = { ...target };
      const cp = Object.assign({}, target);
      Object.keys(cp).forEach(k => {
        cp[k] = this.deepCopy(cp[k]);
      });
      return cp;
    }
    return target;
  }
}
