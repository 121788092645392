import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { WorkflowService } from "framework/workflow.service";
import { LocalStorageService } from "framework/localstorage.service";
import { Observable } from "rxjs";
import { AppConfig } from "app/app.config";
import { map, catchError } from "rxjs/operators";
import { throwError } from 'rxjs';


@Injectable()
export class CustomFormService {
    headerOptions: any;
    customConfig: Object;
    nextURL: string;
    action: string;
    nextDisplayName: string;
    workflow_instance_id: string;
    private serverData: Object[];

    constructor(
        private _http: HttpClient,
        private _workflow: WorkflowService, private localStorage: LocalStorageService,) {
        this.workflow_instance_id = this.localStorage.getItem('workflow_instance_id');
        this.headerOptions = this._workflow.getHeaderOptions();
    }

    setStageName(stageName: string, isCustomGroup: boolean) {
        if (isCustomGroup) {
            this.customConfig = this._workflow.getStepConfig('custom-form-data-v1', 0);
        } else {
            this.customConfig = this._workflow.getStepConfig(stageName, 0);
        }
        this.nextURL = this._workflow.getNextURL(stageName);
        this.nextDisplayName = this._workflow.getNextMenuDisplayName(stageName);
        this.nextDisplayName = this._workflow.getGroupDisplayId(this.nextDisplayName);
        if(!!this._workflow.getGroupNameFormIdMap()[this.nextDisplayName]){
            this.nextDisplayName = this._workflow.getGroupNameFormIdMap()[this.nextDisplayName];
        }
        this.action = this.customConfig['action'];
    }

    getCustomFormData(groupName, isCustomGroup: boolean) {
        let formFieldList = [];
        /*
         * there is a chance that 'forms' key can have 'candidate_form_fields_list' key & in that case
         * value for 'candidate_form_fields_list' key will be returned
        */
        if (this.customConfig['forms'].length > 0) {
            for (let i in this.customConfig['forms']) {
                if (this.customConfig['forms'][i]['candidate_form_fields_list'] &&
                    this.customConfig['forms'][i]['candidate_form_fields_list'].length > 0) {
                    if (isCustomGroup) {
                        if (this.customConfig['forms'][i]['group_name'] &&
                            this.customConfig['forms'][i]['group_name'] === groupName) {
                            formFieldList.push(this.deepCopy(this.customConfig['forms'][i]));
                        }
                    } else {
                        formFieldList.push(this.deepCopy(this.customConfig['forms'][i]));
                    }
                }
            }
        }
        return (formFieldList === undefined) ? this.customConfig['forms'] : formFieldList;
    }

    getNextURL(): string {
        return this.nextURL;
    }

    getNextDisplayName() {
        return this.nextDisplayName;
    }

    getData(stageDisplayName) {
        let workflowid = this.localStorage.getItem('workflow_instance_id');
        let action = '/api/v1/workflow/' + workflowid + '/custom-form-step/' + stageDisplayName;

        return this._http.get(AppConfig.API_ENDPOINT() + action, this.headerOptions)
            .pipe(
                map(res => this._extractData(res)),
                map(res => this._doAction(res)),
                catchError(err => this._handleError(err, 'customForm_get_failed'))
            )
    }

    getServerData(): Object[] {
        return this.serverData;
    }

    setServerData(data) {
        this.serverData = data;
    }

    save(data, stageName): Observable<any> {
        let workflowid = this.localStorage.getItem('workflow_instance_id');
        let action = '/api/v1/workflow/' + workflowid + '/custom-form-step/' + stageName;
        let body = data;

        return this._http.put(AppConfig.API_ENDPOINT() + action, body, this.headerOptions)
            .pipe(
                map(res => this._extractData(res)),
                map(res => this._doAction(res)),
                catchError(err => this._handleError(err, 'customForm_put_failed'))
            )
    }

    deleteData(id): Observable<any> {
        let workflowid = this.localStorage.getItem('workflow_instance_id');
        let action = '/api/v1/workflow/' + workflowid + '/custom-form-step/' + id;
        return this._http.delete(AppConfig.API_ENDPOINT() + action, this.headerOptions)
            .pipe(
                map(res => this._extractData(res)),
                map(res => this._doAction(res)),
                catchError(err => this._handleError(err, 'customForm_delete_failed'))
            )
    }

    _extractData(res: any) {
        return res;
    }

    _doAction(response: any) {
        return response;
    }

    _handleError(error: any, eventName) {
        let _body = error || {};
        let errorMessage: string;

        console['server'](eventName, error);

        switch (error.status) {
            case 400:
            case 401:
                errorMessage = _body['error']['error'] || 'API Call Failed';
                break;
            case 404:
                break;
            default:
                break;
        }
        return throwError(errorMessage);
    }

    deepCopy(target: any): any {
        if (target === null) {
            return target;
        }
        if (target instanceof Date) {
            return new Date(target.getTime());
        }
        if (target instanceof Array) {
            const cp = [];
            target.forEach((v) => { cp.push(v); });
            return cp.map((n: any) => this.deepCopy(n));
        }
        if (typeof target === 'object' && target !== null && Object.keys(target).length > 0) {
            // const cp = { ...target };
            const cp = Object.assign({}, target);
            Object.keys(cp).forEach(k => {
                cp[k] = this.deepCopy(cp[k]);
            });
            return cp;
        }
        return target;
    };

    checkSpecificConsent(name: string) {
        if (this.customConfig['forms'].length > 0) {
            for (let i in this.customConfig['forms']) {
                if (this.customConfig['forms'][i]['name'].indexOf(name) > -1) { //TODO change name to key for only NVNP
                    return true;
                } else {
                    return false;
                }
            }
        }
    }

    // Method to donwload file
    downloadFile(docBaseId, locationCode): Observable<Blob> {
        let workflowid = this.localStorage.getItem('workflow_instance_id');
        let action = '/api/v1/workflow/' + workflowid + '/download/';

        return this._http.get(AppConfig.API_ENDPOINT() + action + docBaseId + '/?doclocation=' + locationCode, {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.localStorage.getItem('access_token')
            }), responseType: 'blob'
        }).pipe(
            map(res => this.documentDownload(res)),
            catchError(err => this._handleError(err, 'download_file_failed'))
        )
    }

    documentDownload(response: any) {
        let res = response;

        return res;
    }
}